@media screen and (max-width: 400px) {
  #profiles {
    padding: 20px;
    width: 111%;
  }
  #about,
  #crates,
  #publications,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.markdownStyle {
  display: block;
  white-space: pre-wrap;
  text-align: left;
  border: #6372ff50 1px solid;
  margin-bottom: 20px;
  border-top: none;
  padding: 20px;
  border-radius: 0 0 4px 4px;
}

.markdownStyleAboutUs {
  display: block;
  text-align: left;
  border: #6372ff50 1px solid;
  margin-bottom: 20px;
  border-top: none;
  border-bottom: none;
  padding: 20px;
}

/*make animation that will drop down content*/
@keyframes slideDown {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 100%;
    opacity: 1;
  }
}

.collapsible {
  background-color: #6372ff50;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  /*have to the op corners be with a borde radius*/
  border-radius: 4px 4px 0 0;
}

.align-right {
  text-align: right;
}

.fileitem {
  border: 1px solid #bbb;
  padding: 10px;
  margin: 20px;
  border-radius: 4px;
  column-count: 2;
}

.fileitem_direct_child {
  padding: 0.2rem;
  border-radius: 4px;
}

.fileitem_direct_child:hover {
  background-color: #6372ff50;
}

.react-icons {
  margin: 1.6rem;
  font-size: 4rem;
  color : #6372ff;
}

.react-icons:hover {
  color: black;
  animation: iconGrow 0.3s ease-in-out forwards ; /*make the icon stay growsn */
}


.cratecard:hover > i {
  animation: iconGrow 1s infinite ease-in-out alternate;
}

.flexrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer_logo {
  height: auto;
  max-height: 20px;
  object-fit: contain;
  margin-left: 5px;
  margin-right: 5px;
}

/*animation to make the icons bigger when hovered*/
@keyframes iconGrow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}